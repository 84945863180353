import React from "react";
import { Link, graphql } from "gatsby";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
export default function Resource({ data }) {
  const featuredPosts = data.featuredQuery.edges;
  return (
    <Layout>
      <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#fff]">
        <div className="container fade-in-animation">
          <h1 className="first-comesup text-[38px] leading-[51px] md:text-[50px] font-heading font-semibold lg:text-[60px] md:leading-[68px] lg:leading-[84px] tracking-[.08em] lg:tracking-[.01em] md:mb-[60px] mb-[40px]">
            Design Resources
          </h1>
          <p className="second-comesup md:tracking-[.03em] tracking-[.02em] md:text-[16px] md:leading-[1.8750] text-[15px] lg:text-[18px] lg:leading-[1.67] leading-[1.733] lg:max-w-[800px] max-w-[688px] lg:mb-[100px] md:mb-[80px] mb-[60px]">
            We are your one-stop destination for a plethora of free design tools
            and kits that will elevate your creative projects. Explore our
            curated collection of resources designed to empower your ideas.
            Download and utilise these assets to streamline your design process
            and bring your vision to life.
          </p>
          <div className="flex lg:gap-[100px] md:gap-[80px] gap-[60px] flex-col blog-list">
            {featuredPosts.map(({ node }) => (
              <Link
                to={`/resources/${node.slug}/`}
                className="flex lg:gap-[70px] md:gap-[30px] gap-[20px] lg:pb-[100px] pb-[50px] items-center flex-wrap md:flex-nowrap"
                key={node.slug}
              >
                <div className="lg:min-w-[400px] lg:max-w-[400px] md:min-w-[269px] md:w-[269px]">
                  {node.featuredImage && node.featuredImage.node.sourceUrl && (
                    <img
                      className="w-[100%] h-[auto] object-cover"
                      src={node.featuredImage.node.sourceUrl}
                      alt={node.featuredImage.node.sourceUrl}
                    />
                  )}
                </div>
                <div className="lg:min-w-[597px] lg:w-[597px] md:min-w-[389px] md:w-[389px] dynamic-content">
                  <h2 className="text-[30px]  md:text-[40px] lg:text-[50px] font-medium font-heading tracking-[0.08em] md:leading-[1.3] leading-[normal] lg:mb-[15px] mb-[10px] font-less">
                    {node.title}
                  </h2>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
      {/* <KnowMoreSection Include="Home" /> */}
    </Layout>
  );
}

export const Head = () => (
  <Seo
    title="Design Resources: Fueling Creativity with Essential Assets"
    description="Explore a curated collection of high-quality design resources from graphics to templates, empowering your creative projects with inspiration and efficiency."
  />
);

export const pageQuery = graphql`
  query {
    featuredQuery: allWpResource {
      edges {
        node {
          title
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          resources {
            gumroadLink
          }
        }
      }
    }
  }
`;
